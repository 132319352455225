import React from 'react'
const Video = () => (
    <>
        <section className="video">
            <h2 className="videoTitle">Veja como a M1 Motors pode mudar sua vida</h2>
            <div>
                <iframe width="100%" height="694"
                    src="https://www.youtube.com/embed/96Uu0HG6CI8?autoplay=1&rel=0"
                    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
            </div>

            <h2 className="videoTitle" style={{marginTop: '8%'}}>Aumente Seus Ganhos: Lucre
                <span style={{ color: '#FF3038' }}> R$5.000,00*</span>
            </h2>
            <div className="containerLucro">
                <div className='circleLucro'>
                    <span className='valueLucro'>R$50*</span>
                </div>
                <h1>POR CADA AVALIAÇÃO<br /> DE CADA VEÍCULO</h1>

                <div className='circleLucro'>
                    <span className='valueLucro'>1,5%</span>
                </div>
                <h1>POR CADA VEÍCULO <br />QUE FOR VENDIDO</h1>

                <div className='circleLucro'>
                    <span className='valueLucro'>1,0%</span>
                </div>
                <h1>NA VENDA DE<br /> CONSÓRCIO</h1>

                <div className='circleLucro'>
                    <span className='valueLucro'>1,0%</span>
                </div>
                <h1>NA VENDA DA<br /> GARANTIA EXTENDIDA</h1>
            </div>
            <div style={{marginTop: '-7%'}}>
            <span className='spanLucro'>*Valores aproximados. *Sobre o valor do faturamento.</span>
            </div>
            
            <div className="buttonContainer">
                <a href="#formulario"
                    className="btns"
                    rel="noopener noreferrer"
                >
                    <span className="ctaText">Garanta Sua Liberdade Agora!</span>
                </a>
            </div>
        </section>
    </>
);

export default Video;