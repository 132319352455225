import React from 'react'
import logo from '../img/m1branco.png'

const Footer = () => (
  <footer className="footer">
    <img src={logo} style={{width: '125px', height: 'auto'}} alt="Logo" />
    <a href="https://www.instagram.com/m1motorsoficial" target="_blank"
      rel="noopener noreferrer"
      style={{ color: '#fff', textDecoration: 'none' }}
    >
      <span>@m1motorsoficial</span>
    </a>
  </footer>
);

export default Footer;