import React from 'react'
import { Icon } from '@iconify/react'
import beneft from '../img/benefit.png'

const KitIron = () => (
  <section className="benefits" id="benefits">
    <h2 className="benefitsTitle">Benefícios Principais</h2>

    <div className="circlesContainer">
      <div className="circleItem">
        <div className="circle">
          <Icon icon="material-symbols:nature-people-outline" />
        </div>
        <h3 className="circleTitle">Trabalhe ao Ar Livre</h3>
        <p className="circleSubtitle">Para quem adora estar em movimento e em contato direto com o público.</p>
      </div>

      <div className="circleItem">
        <div className="circle">
          <Icon icon="healthicons:money-bag" />
        </div>
        <h3 className="circleTitle">Comissões Atrativas</h3>
        <p className="circleSubtitle">Ganhe mais pelo seu esforço e talento nas vendas.</p>
      </div>

      <div className="circleItem">
        <div className="circle">
          <Icon icon="mdi:home-time-outline" />
        </div>
        <h3 className="circleTitle">Flexibilidade</h3>
        <p className="circleSubtitle">Defina seus próprios horários e escolha onde quer trabalhar.</p>
      </div>

      <div className="circleItem">
        <div className="circle">
          <Icon icon="fluent-mdl2:learning-tools" />
        </div>
        <h3 className="circleTitle">Kit Franqueado Exclusivo</h3>
        <p className="circleSubtitle">Ferramentas de qualidade para você começar com o pé direito.</p>
      </div>
    </div>
    <div className="imgBenefit">
      <img src={beneft} />
    </div>
  </section>
);

export default KitIron;